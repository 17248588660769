import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { validLinkPattern } from "../../../RegExpValidations";

export default class extends Controller {
  selectedCheckboxes = [];

  static targets = [
    "firstName",
    "tab1",
    "dateOfBirth",
    "lastName",
    "username",
    "phone",
    "companyName",
    "address",
    "alert",
    "tab2",
    "language",
    "timezone",
    "password",
    "plainPassword",
    "tab3",
    "tab4",
    "advCategory",
    "alertError",
    "companyEmail",
    "website",
    "additionalPhone",
    "taxNumber",
    "companyEmailErr",
    "companyWebsiteErr",
    "dateErr",
    "phoneErr",
    "addPhoneErr",
    "passwordErr",
    "addBtn",
    "removeBtn",
    "sharedEmail",
    "accessType",
    "userTable",
    "pagination",
    "alertWarning",
    "notificationSubmitButton",
    "newPassword",
    "forgotPassword",
    "resetPasswordUsername",
    "alertSuccess",
    "resetPasswordBtn",
    "googleAuthenticator",
  ];

  iti;
  itiAdditional;
  errorValidation = false;
  moment = null;
  intlTelInput = null;
  datepicker = null;

  async connect() {
    this.moment = (await import("moment")).default;
    this.intlTelInput = (await import("intl-tel-input")).default;
    this.datepicker = (await import("js-datepicker")).default;

    const d = this.datepicker("#datepicker", {
      formatter: (input, date) => {
        const formattedDate = this.moment(date).format("MM.DD.YYYY");
        input.value = formattedDate;
      },
    });
    if (this.dateOfBirthTarget.value) {
      const date = this.moment(
        this.dateOfBirthTarget.value,
        "MM.DD.YYYY"
      ).toDate();
      d.setDate(date);
    }
    const options = {
      //   initialCountry: "auto", // Auto-detect user's country
      separateDialCode: true,
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js", // Load utils script

      initialCountry: "us",
    };
    this.iti = this.intlTelInput(this.phoneTarget, options);
    this.itiAdditional = this.intlTelInput(this.additionalPhoneTarget, options);

    const checkboxes = this.element.querySelectorAll(
      'input[name="types"]:checked'
    );
    const selectedCheckboxes = Array.from(checkboxes).map(
      (checkbox) => checkbox.value
    );
    if (selectedCheckboxes.length > 0) {
      this.selectedCheckboxes = selectedCheckboxes;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('tab');

    if (myParam === "notification") {
      let flashEl = document.querySelector(".flash-message");
      let notificationType = flashEl.getAttribute("data-lable");
      let notificationMessage = flashEl.getAttribute("data-message")
      if (notificationType !== "" && notificationMessage !== "") {
        console.log(notificationType, notificationMessage)
        document.querySelector("#pills-notification-tab").click();
        if (notificationType === "success") {
          this.showSuccessAlert();
        } else {
          this.showAlert(notificationMessage, notificationType);
        }
      }
    }
  }

  async deleteUser(e) {
    e.preventDefault();
    try {
      await axios.post(e.target.dataset.href);
      window.location.href = "/";
    } catch (err) {}
  }

  async updateGeneral(e) {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const datePattern =
      /^(0[1-9]|1[0-2])\.(0[1-9]|[1-2][0-9]|3[0-1])\.(19|20)\d{2}$/;
    const phonePattern = /^[0-9]+$/;

    this.errorValidation = false;
    this.validateInputWithErr(
      this.companyEmailTarget.value,
      emailPattern,
      this.companyEmailErrTarget
    );
    this.validateInputWithErr(
      this.websiteTarget.value,
      validLinkPattern,
      this.companyWebsiteErrTarget
    );
    this.validateInputWithErr(
      this.dateOfBirthTarget.value,
      datePattern,
      this.dateErrTarget
    );

    let phone = this.phoneTarget.value;

    if (phone) {
      phone = phone.replace(/ +/g, "");
    }

    this.validateInputWithErr(phone, phonePattern, this.phoneErrTarget);
    this.validateInputWithErr(
      this.additionalPhoneTarget.value,
      phonePattern,
      this.addPhoneErrTarget
    );
    if (this.iti.getNumber().length && this.iti.getNumber().length > 30) {
      this.showErrorMsg(this.phoneErrTarget);
      this.errorValidation = true;
    }
    if (
      this.itiAdditional.getNumber().length &&
      this.itiAdditional.getNumber().length > 30
    ) {
      this.showErrorMsg(this.addPhoneErrTarget);
      this.errorValidation = true;
    }
    if (this.errorValidation) return;

    const formData = new FormData();
    formData.append("firstName", this.firstNameTarget.value);
    formData.append("lastName", this.lastNameTarget.value);
    formData.append("tab", this.tab1Target.value);
    formData.append(
      "dateOfBirth",
      this.dateOfBirthTarget.value &&
        this.moment(this.dateOfBirthTarget.value).toString()
    );
    formData.append("username", this.usernameTarget.value);

    formData.append("companyName", this.companyNameTarget.value);
    formData.append("address", this.addressTarget.value);
    formData.append("advCategory", this.advCategoryTarget.value);
    formData.append("companyEmail", this.companyEmailTarget.value);
    formData.append("website", this.websiteTarget.value);
    formData.append("taxNumber", this.taxNumberTarget.value);

    if (this.iti.getNumber().length) {
      formData.append("phones[]", this.iti.getNumber());
    }
    if (this.itiAdditional.getNumber().length) {
      formData.append("phones[]", this.itiAdditional.getNumber());
    }

    try {
      let locale = document
        .querySelector("[data-locale]")
        .getAttribute("data-locale");

      if (locale === undefined) {
        locale = "en";
      }

      formData.append("_locale", locale);

      let data = await axios.post(`/account/update`, formData);

      if (Array.isArray(data.data)) {
        for (const element of data.data) {
          if (element.error) {
            this.showAlert(element.error);
            break;
          }
        }
      }

      this.showSuccessAlert();
    } catch (e) {}
  }

  async updateSettings(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("country", this.languageTarget.value);
    formData.append("timezone", this.timezoneTarget.value);
    formData.append("tab", this.tab2Target.value);
    try {
      await axios.post("/account/update", formData);
      this.showSuccessAlert();
      // this.googleAuthenticator()
    } catch (e) {}
  }

  googleAuthenticator() {
      const initialValue = this.googleAuthenticatorTarget.getAttribute('data-initial-value');
      const currentValue = this.googleAuthenticatorTarget.value;
        if (currentValue !== initialValue) {
          window.location.href = '/2fa_setting';
        }
}
  async addAccountAccess(e) {
    e.preventDefault();

    const email = this.sharedEmailTarget.value.trim();
    if (!this.validationEmail(email)) {
      return;
    }

    const formData = new FormData();
    formData.append("newAccessAccount", "true");
    formData.append("sharedEmail", email);
    formData.append("accessType", this.accessTypeTarget.value);
    formData.append("tab", this.tab2Target.value);

    try {
      const response = await axios.post("/account/update", formData);
      if (response.data.success) {
        await axios
          .get("/account/add-user-row", {
            params: {
              sharedEmail: email,
              accessType: this.accessTypeTarget.value,
            },
          })
          .then((response) => {
            const { rowHtml, paginationHtml } = response.data;
            const firstPageLink =
              this.paginationTarget.querySelector("a.page-link");

            if (firstPageLink) {
              this.backToFirstPageLink(firstPageLink);
            } else {
              this.userTableTarget.insertAdjacentHTML("afterbegin", rowHtml);
              this.paginationTarget.innerHTML = paginationHtml;
              this.showSuccessAlert();

              const paginationLinks =
                this.paginationTarget.querySelectorAll("a.page-link");
              paginationLinks.forEach((link) => {
                const page = link.innerText;
                const url = `/account?page=${page}`;
                link.setAttribute("href", url);
              });

              const firstPageLink =
                this.paginationTarget.querySelector("a.page-link");
              if (firstPageLink) {
                this.backToFirstPageLink(firstPageLink);
              }
            }
            document.getElementById("sharedEmail").value = "";
          });
      } else {
        this.showAlert(response.data[0].error);
      }
    } catch (e) {
      console.error(e);
    }
  }

  validationEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      this.showAlert("Please enter an email address.", "warning");
      return false;
    }
    if (!emailRegex.test(email)) {
      this.showAlert("Please enter a valid email address.", "warning");
      return false;
    }
    if (email.length > 255) {
      this.showAlert("Email address is too long.", "warning");
      return false;
    }
    return true;
  }

  backToFirstPageLink(firstPageLink) {
    firstPageLink.href = "/account?page=1";
    firstPageLink.click();
    return true;
  }

  async updatePassword(e) {
    e.preventDefault();
    if (!this.plainPasswordTarget.value.trim().length) {
      this.showErrorMsg(this.passwordErrTarget);
      return;
    }
    const formData = new FormData();
    formData.append("password", this.passwordTarget.value);
    formData.append("plainPassword", this.plainPasswordTarget.value);
    formData.append("tab", this.tab3Target.value);
    try {
      const { data } = await axios.post("/account/update", formData);
      if (Array.isArray(data) && data[0]?.error) {
        this.showAlert(data[0].error);
        return;
      }
      this.showSuccessAlert();
    } catch (e) {
    } finally {
      this.passwordTarget.value = "";
      this.plainPasswordTarget.value = "";
    }
  }

  showSuccessAlert() {
    this.alertTarget.classList.remove("d-none");
    setTimeout(() => this.alertTarget.classList.add("d-none"), 5000);
  }

  showAlert(message, type = "error") {
    const alertTarget =
      type === "warning"
        ? this.alertWarningTarget
        : type === "success"
        ? this.alertSuccessTarget
        : this.alertErrorTarget;
    const alertElement = alertTarget.querySelector(`.${type}`);

    alertTarget.classList.remove("d-none");
    alertElement.innerHTML = message;

    setTimeout(() => {
      alertTarget.classList.add("d-none");
      alertElement.innerHTML = "";
    }, 5000);
  }

  showAdditionalPhone(e) {
    e.target.classList.add("d-none");
    this.additionalPhoneTarget.parentNode.parentNode.classList.remove("d-none");
    this.removeBtnTarget.classList.remove("d-none");
  }

  showErrorMsg(target) {
    target.classList.remove("d-none");
    setTimeout(() => target.classList.add("d-none"), 5000);
  }

  validateInputWithErr(value, pattern, errTarget) {
    if (value.length && !pattern.test(value)) {
      this.showErrorMsg(errTarget);
      this.errorValidation = true;
    }
  }
  removeAdditionalPhone(e) {
    e.target.classList.add("d-none");
    this.additionalPhoneTarget.parentNode.parentNode.classList.add("d-none");
    this.additionalPhoneTarget.value = "";
    this.addBtnTarget.classList.remove("d-none");
  }

  closeAlert(e) {
    e.currentTarget.parentNode.classList.add("d-none");
  }

  async emailNotification(e) {
    let email = document.querySelector("#alternative_email").value.trim();
    const submitButton = this.notificationSubmitButtonTarget;
    submitButton.disabled = true;
    const formData = new FormData();
    const checkboxes = this.element.querySelectorAll(
      'input[name="types"]:checked'
    );
    checkboxes.forEach((checkbox, index) => {
      const checkboxValue = parseInt(checkbox.value);
      formData.append("types[]", checkboxValue);
    });
    formData.append("tab", this.tab4Target.value);

    if (email === "") {
      formData.append("needDeleteEmail", "1");
    }

    try {
      await axios.post("/account/update", formData);
      this.showSuccessAlert();
    } catch (e) {
      submitButton.disabled = false;
    } finally {
      submitButton.disabled = false;
    }
  }

  cancelEmailNotification() {
    const checkboxes = this.element.querySelectorAll('input[name="types"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    checkboxes.forEach((checkbox) => {
      checkbox.checked = this.selectedCheckboxes.includes(checkbox.value);
    });
  }

  openCloseForgotPassword() {
    this.newPasswordTarget.classList.toggle("d-none");
    this.forgotPasswordTarget.classList.toggle("d-none");
  }

  async resetPassword(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", this.resetPasswordUsernameTarget.value);
    const locale = document
      .querySelector("[data-locale]")
      .getAttribute("data-locale");
    formData.append("locale", locale);
    try {
      this.resetPasswordBtnTarget.disabled = true;
      const { data } = await axios.post(e.target.action, formData);
      this.showAlert(data.message, "success");
    } catch (err) {
      this.showAlert(err.response.data.error);
    } finally {
      this.resetPasswordBtnTarget.disabled = false;
    }
  }

  async changeGoogleAuth() {
    const initialValue = this.googleAuthenticatorTarget.checked;
    const needGenerateSecretKey = this.googleAuthenticatorTarget.getAttribute("data-need-generate-secret-key");
    try {
      if (needGenerateSecretKey === "1") {
        await axios.post("/2fa_enable");
      }
      let _locale = document.querySelector("[data-locale]").getAttribute("data-locale") ?? "en";
      let route = "/2fa_setting";

      if (_locale !== "en") {
        route = "/" + _locale + route;
      }

      let response = await axios.get(route);
      let modal = document.querySelector(".modal-body-google-auth");
      modal.innerHTML = response.data;
    } catch (e) {
      console.log(e);
    }
  }

  async alternativeEmailSendVerifyLetter(){

    let userId = document.querySelector("[data-user-id]").getAttribute("data-user-id");
    let email = document.querySelector("#alternative_email").value;
    const formData = new FormData();
    formData.append("email", email);
    let locale = document.querySelector('[data-locale]').getAttribute('data-locale')

    if (locale === undefined) {
      locale = "en";
    }

    formData.append('locale', locale)
    let errorMsg = document.getElementById('errorAlternativeEmail')
    let confirmMsg = document.getElementById('successAlternativeEmail')
    try {

      let resp = await axios.post("/account/send_alternative_email/"+userId, formData);

      confirmMsg.innerText = resp.data.message
      confirmMsg.classList.remove('d-none')
      setTimeout(() => {
        confirmMsg.classList.add('d-none')
        confirmMsg.innerText = ''
      }, 3000);

    }   catch (e) {
    if (e.response) {
      errorMsg.innerText = e.response.data.error
      errorMsg.classList.remove('d-none')
      setTimeout(() => {
        errorMsg.classList.add('d-none')
        errorMsg.innerText = ''
      }, 3000);
    }
    }
  }
}
